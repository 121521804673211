import request from './axios'
let baseUrl = process.env.VUE_APP_API_HOST

export const HOST = 'https://esl.ontimesmart.com'
export const HOSTM = '..'

export const submit = (data) => {
	return request({
		url: baseUrl + '/cgi-bin/sumbitMessage',
		method: 'post',
		data
	})
}