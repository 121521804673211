<template>
  <div>
    <div class="box-box u-flex-col u-rela" :class="index==2?'workbj':''">
      <div>
        <van-sticky>
          <div class="u-flex u-abso u-row-between back-bj" :style="{ ...topNavBg, 'opacity': show ? '0' : '' }">
            <img @click="openWebsite" src="../assets/ontime.png" alt="" class="ontime">
            <img @click="show = !show" src="../assets/menu.png" alt="" class="menu">
          </div>
        </van-sticky>
        <div class="content" v-if="index!=2">
          <h1>ONTIME</h1>
          <h2 class="m-b-35">Electronic Shelf Labels</h2>
          <h3 class="m-b-">The most flexible ESL tag solution available</h3>
          <h3 class="m-b-">in the market today</h3>
          <h3 class="m-b-">A solution that combines hard and software</h3>
          <h3 class="m-b-">in one package</h3>
          <van-button @click="anchor1(8, 1, 'Product')" round color="#F75757" class="contect">Contact us</van-button>
        </div>
		<div class="content badge" v-else>
		  <h1 class="badge_title">Digital Name Badge</h1>
		  <div class="work_text">An Amazing Solution of Badges</div>
		</div>
      </div>
      <van-popup v-model="show">
        <div class="menuList-box">
          <div class="menuList u-flex u-row-between">
            <img @click="openWebsite" src="../assets/ontime.png" alt="" class="ontime">
            <img @click="show = !show" src="../assets/close.png" alt="" class="close">
          </div>
          <div class="u-flex-col">
            <div v-for="(item, i) in navArr" :key="i" @click="change(i, item.id)"
              :class="{ isDefault: true, activate: index == i }">
              <div class="u-flex u-row-between">
                <span class="m-b-20"> {{ item.name }}</span>
                <span class="brackets" :class="{ bracketsDo: indexShow == i }">&lt;</span>
              </div>

              <div class="nav-item-list" :class="{ 'nav-item-listDo': indexShow == i }" @click.stop>
                <a href="javascript:void(0)" class="nav-item-list-item" v-for="(a, k) in item.childList" :key="k"
                  @click.stop="anchor1(k, i, item.id)">
                  {{ a }}
                </a>
              </div>
            </div>
          </div>
          <div class="btnab-box">
            <van-button @click="anchor1(8, 1, 'Product')" round color="#F75757" class="contect">Contact us</van-button>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
// import { Popup } from 'vant';
import { HOSTM} from "@/api/all";
export default {
  name: "NavigAtion",
  props: {
    msg: String,
  },
  data() {
    return {
      showExpo:false,
      show: false,
      index: 0,
      indexShow: 0,
      scrollTop: 0,
      gundong: false,
      topNavBg: {
        height: "3.3rem",
      },
      navArr: [
        {
          name: "Ontime ESL",
          id: "Home",
          childList: ["Benefits", "System", "Application"],
        },
        {
          name: "Product",
          id: "Product",
          childList: ["A1 Labels","S1 Labels","The Multicolor Labels","Access point", "PDA", "Accessory", "Platform"],        
		},
		{
		  name: "Badge",
		  id: "Badge",
		  childList: [
		    "Badge",
		  ],
		},
        {
          name: "About us",
          id: "AboutUs",
          childList: [
            "About us",
          ],
        },
      ],
    }
  },
  computed: {

  },
  watch: {
    $route(val) {
      // console.log(val);
      document.title = val.name;
      switch (this.$route.name) {
        case "Home":
          this.index = 0;
          break;
        case "Product":
          this.index = 1;
          break;
		case "Badge":
		   this.index = 2;
		   break;
        case "AboutUs":
          this.index = 3;
          break;
      }
    },
    scrollTop(nv, ov) {
      // console.log(nv, ov)
      if ((nv - ov) > 0) {
        if (nv > 750) {
          //设置页面滑动多少显示按钮
          // this.goTopShow = true;
          this.topNavBg.height = '0px'
          // this.topNavBg.padding = '0px'
        } else {
          // this.goTopShow = false;
          // delete this.topNavBg.padding;
          this.topNavBg.height = '3.3rem'
        }
      } else {
        if (this.gundong) {
          this.topNavBg.height = '0px'
        } else {
          this.topNavBg.height = '3.3rem'
        }
      }
      this.gundong = false
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      /* if (this.scrollTop) {
        if (this.scrollTop > 750) {
          //设置页面滑动多少显示按钮
          // this.goTopShow = true;
          this.topNavBg.height = '0px'
          // this.topNavBg.padding = '0px'
        } else {
          // this.goTopShow = false;
          // delete this.topNavBg.padding;
          this.topNavBg.height = '3.3rem'
        }
        // scrollTop + 多少根据自己的需求设置
        // this.topNavBg.backgroundColor = `rgba(88, 83, 81,
        //   	${this.scrollTop / (this.scrollTop + 60)})`; 
      } else if (this.scrollTop === 0) {
        // delete this.topNavBg.padding;
        this.topNavBg.height = '3.3rem'
        // 设置回到顶部时，背景颜色为透明
        // this.topNavBg.backgroundColor = "transparent"; 
      } */
    },
    change(index, id) {
      console.log(index, id);
      if (this.indexShow != index) {
        this.indexShow = index;
        // this.$router.push({ name: id });
      } else {
        this.indexShow = null
      }
    },
    openWebsite() {
      window.location.href = HOSTM;
    },
    anchor1(k, index, id) {
      console.log(`${id}-${k + 1}`)
      console.log(k, index, id);
      
      this.show = false
      if (this.index != index) {
        this.index = index;
        this.$router.push({ name: id });
      }
      this.gundong = true
      setTimeout(() => {
        let idTop = document.querySelector(`#${id}-${k + 1}`).offsetTop;
        console.log(idTop,'console.log(idTop)')
        document.documentElement.scrollTop = idTop;
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.van-popup.van-popup--center {
  // left: unset;
  // top: unset;
  display: flex;
  background: rgba($color: #000000, $alpha: 0.6);
}

.menuList-box {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .isDefault {
    color: #999999;
    font-size: 62px;
    font-weight: Bold;
    padding: 40px 0;
    margin: 0 32px;
    border-bottom: 1px solid rgba($color: #FFFFFF, $alpha: 0.3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .activate {
    color: #FFFFFF;
  }

  .brackets {
    display: block;
    font-size: 36px;
    margin-right: 10px;
    margin-top: 1px;
    transition: 0.3s;
    transform: scale(1.9, 1) rotate(-90deg);
  }

  .bracketsDo {
    transform: scale(1.9, 1) rotate(90deg);
  }

  .nav-item-list {
    max-height: 0;
    min-width: 100px;
    transition: all 0.5s;
    overflow: hidden;
    font-weight: initial;
    text-align: left;

    a {
      padding: 30px 0;
      display: block;
      color: #ffffff;
      white-space: nowrap;
      font-size: 30px;
    }
  }

  .nav-item-listDo {
    max-height: 600px;
	}

  .btnab-box {
    position: absolute;
    bottom: 126px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }


}

.menuList {
  width: 100%;
  height: 3.3rem;

  .close {
    width: 56px;
    height: 56px;
    margin: 46px 21px 46px 21px;
  }
}

.box-box {
  height: 100vh;
  max-height: 1334px;
  background: url(@/assets/eslBj.png) no-repeat center / 100% 100%;
  // background: url(@/assets/point.png) no-repeat center / 100% 100%;

  .back-bj {
    background-color: #000000;
    overflow: hidden;
    transition: all .3s;
    height: 66px;
    width: 100%;
  }

  .ontime {
    width: 88px;
    height: 56px;
    margin: 46px 21px 46px 21px;
  }

  .menu {
    width: 45px;
    height: 36px;
    margin: 46px 21px 46px 21px;
  }

  .content {
    color: #fff;
    padding: 0 31px;
    padding-top: 260px;

    h1 {
      font-weight: bold;
      font-size: 100px;
      line-height: 101px;
      margin: 0;
    }

    h2 {
      font-weight: bold;
      font-size: 62px;
      line-height: 76px;
    }

    h3 {
      font-size: 30px;
      font-family: Arial;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.7);
      line-height: 60px;
    }


  }

  .contect {
    width: 300px;
    height: 80px;
    font-size: 36px;
    font-weight: bold;
    margin-top: 60px;
  }
}
.badge{
	width: 100%;
	height: 100%;
	padding-top: 560px !important;
}
.badge_title{
	font-size: 64px !important;
	font-family: SourceHanSansSC, SourceHanSansSC;
	font-weight: bold !important;
	color: #FFFFFF;
	line-height: 46px;
	text-align: center;
}
.work_text{
	font-size: 36px;
	font-family: SourceHanSansSC, SourceHanSansSC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 52px;
	margin-top: 14px;
	text-align: center;
}
.workbj{
	height: 100vh;
	max-height: 1334px;
	background: url(@/assets/workcard.png) no-repeat center / 100% 100%;
}
</style>