import axios from 'axios'

axios.defaults.timeout = 600000;

axios.interceptors.request.use(
    config => {
      
        return config
    },
    error => {
        // 请求错误
        return Promise.reject(error)
    }
)
// response interceptor
axios.interceptors.response.use(
    response => {
      
        return response
    },
    error => {
        /* 在这里设置token过期的跳转*/
        return Promise.reject(error)
       
    })

export default axios;